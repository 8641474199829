import type {Namespace} from "~/types/spaces.types";
import {useStream} from "~/stores/_archetypes/rsocket";
import type {Ref} from "vue";

export const useNamespace = (workspaceId: string, environmentId: string): {
    namespace: Ref<Namespace>;
    loading: Ref<boolean>;
    error: Ref<boolean>
} => {
    const streamState = useStream<Namespace, Namespace, Namespace>(
        'namespace/stream',
        {workspaceId, namespaceId: environmentId},
        data => data,
        (data, s) => data
    );
    return {namespace: streamState.item, loading: streamState.loading, error: streamState.error}
}